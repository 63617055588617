<template>
  <div style="min-height:100vh;background:#f3f3f3">
    <van-sticky>
      <div class="header" style="background:#f3f3f3">
        <van-row gutter="10" style="width:100%">
          <van-col span="12" @click="onClickBack">
            <svg-icon
              :data_iconName="'back-active'"
              :className="'back_icon'"
              style="height: 25px;color:inherit"
            />
            <span style="font-size: 25px; color: #333;font-weight:bold">个人中心</span>
          </van-col>
          <!-- <van-col span="12" class="header_action_title"
            @click="submit">完成</van-col> -->
        </van-row>
      </div>
    </van-sticky>
    <div style="margin-top:30px;padding: .4rem .2rem 4rem .2rem;">
      <card class="form-body">
        <template #content>
          <fieldForm
            :submit="submit"
            :modal="user"
            :fieldData="fields"
            v-on:date-select="onDateSelect"
            v-on:formUpdate="onFormUpdate"
          ></fieldForm>
          <van-cell is-link border center size="small" :clickable="false">
            <template #title>
              <span style="margin-left:.3rem">地区</span>
            </template>
            <template #right-icon>
              <van-field type="text" :border="false" disabled v-model="personInfo.guide.location_str" style="width:60%"/>
              <van-icon name="arrow" />
            </template>
          </van-cell>
          <van-cell border clickable is-link size="small" to="/wode/changePassword">
            <template #title>
              <span style="margin-left:.3rem">修改密码</span>
            </template>
          </van-cell>
        </template>
      </card>
      <van-action-sheet v-model="showDateTypeSelection" :actions="dateTypes" @select="onDateTypeSelect"
        cancel-text="取消" close-on-click-action/>
      <van-action-sheet v-model="showDateSelect" :overlay="true">
        <van-datetime-picker ref="dateSelector"
          v-model="currentDate"
          type="date"
          title="选择年月日"
          :min-date="minDate"
          :max-date="maxDate"
          v-on:blur="emitChange()"
          @confirm="onDateConfirm"
          @cancel="showDateSelect = false"
        />
      </van-action-sheet>
    </div>
    <!-- <div v-if="!isEdit" class="action_bg">
      <van-col span="6">
        <van-button
          type="info"
          round
          class="action_btn"
          color="#395EE8"
          @click="isEdit = true"
          >编辑</van-button
        >
      </van-col>
    </div> -->
  </div>
</template>

<script>
import api from "@/services/apis.js";
import fieldForm from "@/views/components/FieldForm";
import card from "@/views/components/Card"
import {mapGetters} from "vuex"
import {Toast} from 'vant'
import {
  validateNumberAndLetter,
  validateIdCard,
} from "@/utils/validators"
export default {
  components: {
    fieldForm,
    card
  },
  data() {
    return {
      isEdit: false,
      showDateTypeSelection: false,
      dateTypes: [ { name: '具体日期' }, { name: '长期' } ],
      currentDateSelectItem: {},
      showDateSelect: false,
      currentDate: new Date(),
      minDate: new Date(new Date().getFullYear() - 20, 0, 1),
      maxDate: new Date(new Date().getFullYear() + 20, 11, 31),
      fields: [
        {
          title: "头像",
          name: "avatar",
          type: "file",
          rule: [],
          max_count: 1,
          compressed: true,
          inline: false,
        },
        {
          title: "姓名",
          name: "name",
          required: true,
          type: "text",
          rule: [
            { required: true, message: "" },
            {
              validator: this.nameLengthValidate,
              message: "名字过长"
            }
          ],
          inline: false,
        },
        {
          title: "性别",
          name: "gender",
          required: true,
          type: "select",
          values: ['男', '女'],
          rule: [{ required: true, message: "" }],
          inline: false
        },
        {
          title: "电话",
          name: "mobile",
          type: "text",
          inline: false,
          disabled: true,
          rule: [{ required: true, message: "" }],
        },
        {
          title: "身份证号",
          name: "id_card",
          type: "text",
          required: true,
          inline: false,
          rule: [
            { required: true, message: "" },
            {
              validator: validateIdCard,
              message: "身份证格式不正确"
            }
          ],
        },
        {
          title: "角色",
          name: "role_name",
          type: "select",
          values: ['导游', '讲解员'],
          required: true,
          rule: [{ required: true, message: "" }],
          inline: false,
          disabled: true
        },
        
      ],
      guideFormFields: [
        {
          title: "导游证号",
          name: "tourist_card",
          type: "text",
          inline: false,
          required: true,
          rule: [
            {
              validator: validateNumberAndLetter,
              message: "请输入正确的格式"
            },
            { required: true, message: "" }
          ],
        },
        {
          title: "导游证照",
          name: "tourist_card_image",
          type: "file",
          inline: false,
          max_count: 1,
          compressed: true,
          rule: [],
        },
        {
          title: "导游资格证号",
          name: "tourist_certificate",
          type: "text",
          inline: false,
          required: true,
          rule: [
            {
              validator: validateNumberAndLetter,
              message: "请输入正确的格式"
            },
            { required: true, message: "" }
          ],
        },
        {
          title: "导游资格证照",
          name: "tourist_certificate_image",
          type: "file",
          inline: false,
          multiple: true,
          compressed: true,
          rule: [],
        },
        {
          title: "导游证起始日",
          name: "establish_date",
          type: "date_select",
          required: true,
          inline: false,
          maxDate: new Date(new Date().getFullYear() + 20, 11, 31),
          minDate: new Date(new Date().getFullYear() - 20, 1, 1),
          rule: [
            {
              required: true,
              message: ""
            }
          ],
        },
        {
          title: "导游证有效期",
          name: "valid_period",
          type: "date_select",
          maxDate: new Date(new Date().getFullYear() + 20, 11, 31),
          inline: false,
          required: true,
          customField: true,
          rule: [
            {
              required: true,
              message: ""
            }
          ],
        },
        {
          title: "导游等级",
          name: "level",
          type: "select",
          values: ['初级', '中级', '高级', '特级'],
          inline: false,
          required: true,
          rule: [{required: true, message: ""}],
        },
        {
          title: "语种",
          name: "language",
          type: "multi_select",
          values: [
            { id: 1, value: '普通话'}, 
            { id: 2, value: '英语'}, 
            { id: 3, value: '其他'}, 
          ],
          inline: false,
          required: true,
          rule: [{required: true, message: ""}],
        },
      ],
      jiangjieFormFields: [
         {
          title: "讲解员证号",
          name: "tourist_card",
          type: "text",
          inline: false,
          required: true,
          rule: [
            {
              validator: validateNumberAndLetter,
              message: "请输入正确的格式"
            },
            { required: true, message: "" }
          ],
        },
        {
          title: "讲解员证照",
          name: "tourist_card_image",
          type: "file",
          inline: false,
          max_count: 1,
          rule: [],
        },
        {
          title: "讲解员资格证号",
          name: "tourist_certificate",
          type: "text",
          inline: false,
          required: true,
          rule: [
            {
              validator: validateNumberAndLetter,
              message: "请输入正确的格式"
            },
            { required: true, message: "" }
          ],
        },
        {
          title: "讲解员资格证照",
          name: "tourist_certificate_image",
          type: "file",
          multiple: true,
          inline: false,
          rule: [],
        },
        {
          title: "讲解员证起始日",
          name: "establish_date",
          type: "date_select",
          required: true,
          inline: false,
          maxDate: new Date(new Date().getFullYear() + 20, 11, 31),
          rule: [
            {
              required: true,
              message: ""
            }
          ],
        },
        {
          title: "讲解员证有效期",
          name: "valid_period",
          type: "date_select",
          required: true,
          inline: false,
          maxDate: new Date(new Date().getFullYear() + 20, 11, 31),
          rule: [
            {
              required: true,
              message: ""
            }
          ],
        },
        {
          title: "语种",
          name: "language",
          type: "multi_select",
          values: [
            { id: 1, value: '普通话'}, 
            { id: 2, value: '英语'}, 
            { id: 3, value: '其他'}, 
          ],
          inline: false,
          required: true,
          rule: [{required: true, message: ""}],
        },
      ]
    };
  },
  created() {
    this.$store.dispatch('wode/getPersonInfo').then(personInfo => {
      if(personInfo.guide.role_name == '讲解员') {
        this.jiangjieFormFields.forEach(ele => {
          this.fields.splice(this.fields.length, 0, ele)
        })
      } else {
        this.guideFormFields.forEach(ele => {
          this.fields.splice(this.fields.length, 0, ele)
        })
      }
    })
  },
  computed: {
    ...mapGetters('wode', {
      user: 'getFmtPersonInfo',
      personInfo: 'getPersonInfo'
    }),
  },
  methods: {
    submit() {
      const _self = this
      console.log('----',this.personInfo)
      console.log('----',this.user)
      if(this.personInfo.guide.status=='3'){
        this.user.commit_type = 2
      }else{
       this.user.commit_type = 1
      }
      if(this.user.valid_period == '长期') {
        this.user.valid_period = new Date(2999, 11, 31)
      }
      api.update_guide(this.user).then(function(res) {
        if(res.data.status != 200) {
          Toast(res.data.message)
        } else {
          _self.$router.push('/home')
        }
      })
    },
    
    onClickBack() {
    this.$router.push('/home')
    },
    nameLengthValidate(val) {
      return val.length < 6
    },
    onDateSelect(item) {
      this.currentDateSelectItem = item
      this.showDateTypeSelection = true
    },
    onDateTypeSelect(item) {
      console.log(item);
      if(item.name == '长期') {
        this.personInfo.guide.valid_period = item.name
        this.$store.dispatch('wode/syncPersonInfo', this.personInfo)
      } else {
        this.showDateSelect = true
        this.showDateTypeSelection = false
      }
    },
    onDateConfirm(date){
      this.personInfo.guide[this.currentDateSelectItem.name] = `${date.getFullYear()}-${
        date.getMonth() + 1 }-${date.getDate()}`
      this.showDateSelect = false
    },
    onFormUpdate(data) {
      console.log(data);
      const personInfo = this.personInfo
      personInfo.guide.name = data.name
      personInfo.guide.tourist_card = data.tourist_card
      personInfo.guide.tourist_certificate = data.tourist_certificate
      personInfo.guide.language = data.language
      personInfo.user.avatar = data.avatar
      personInfo.user.mobile = data.mobile
      personInfo.guide.gender = data.gender
      personInfo.guide.tourist_certificate_image = data.tourist_certificate_image
      personInfo.guide.tourist_card_image = data.tourist_card_image
      personInfo.guide.level = data.level
      personInfo.guide.id_card = data.id_card
      personInfo.guide.valid_period = data.valid_period
      personInfo.guide.establish_date = data.establish_date

      this.$store.dispatch('wode/syncPersonInfo', personInfo)
    }
  },
};
</script>

<style scoped>
.form-body {
  height:60vh;
  overflow-y: scroll;
}
.action_btn {
  position: fixed;
  bottom: 10px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  height: 50px;
  left: 2.5%;
  text-align: center;
}
.action_bg {
  padding-top: 10px;
  position: fixed;
  bottom: 0px;
  height: 70px;
  width: 100%;
  left: 0px;
  text-align: center;
  background-color: white;
  z-index: 666;
}
.header_action_title {
  font-size: 25px; color: #333;font-weight:bold;
  text-align:right;line-height:50px;padding-top:.1rem;
  padding:0 .2rem 0 0;
}
.fix-header {
  position:fixed;
  top: 0;
  z-index: 999;
}
::v-deep .card-item {
  background: #ffffff;
  box-shadow: 0 0 6px 0 rgba(182, 179, 179, 0.54);
  border-radius: 20px;
  border-radius: 20px;
  padding: 0.1rem;
  position: relative;
  margin-bottom: 10px;
}
::v-deep .van-uploader__preview-image {
    float: right;
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    overflow: hidden;
}
::v-deep .van-uploader__upload {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin: 5px 0 0 5px;
    background-color: #f7f8fa;
}
::v-deep .van-uploader__wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    float: right;
}
::v-deep .van-uploader__preview {
    position: relative;
    margin: 5px 0 0 5px;
    cursor: pointer;
}
</style>